import React, { FC, useEffect } from 'react';

import { EventDataProps, TagEventTrackerProps } from './models';

const TagEventTracker: FC<TagEventTrackerProps> = ({
  children,
  eventType,
  data: { value, userId, itemListId, itemListName, currency, ecommerce, pageTemplate },
}) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      switch (eventType) {
        case 'view_item':
          window.dataLayer.push({
            event: eventType,
            userId,
            currency,
            value,
            ecommerce,
          });
          break;
        case 'view_item_list':
          if (ecommerce?.items?.length !== 0) {
            window.dataLayer.push({
              event: eventType,
              userId,
              itemListId,
              itemListName,
              ecommerce,
            });
          }
          break;
        case 'page_view':
          window.dataLayer.push({
            pageTemplate,
            userId,
          });
          break;

        default:
          console.error('No dataLayer event found');
          break;
      }
    }
  }, [ecommerce, value, eventType, currency, itemListId, itemListName, pageTemplate, userId]);

  return <>{children}</>;
};

const handleDataLayerClick = (
  { retailerName, userId, ecommerce }: EventDataProps,
  eventType: string,
  url?: string
) => {
  window.dataLayer.push({
    event: eventType,
    retailerName,
    userId,
    ecommerce,
  });
  if (eventType === 'retailer_link_click') {
    window.open(url, '_blank');
  }
};
export { handleDataLayerClick, TagEventTracker };
